////
/// This is a poster comment.
/// Custom Sass Stylesheet
/// Compiles to custom.css
/// Imports all the custom styles
/// @group Shamco
/// @author Matt Urenovich
////

@import 'custom/variables';
@import 'custom/global';
@import 'custom/header';
@import 'custom/homepage';
@import 'custom/properties';
@import 'custom/pay-online';
@import 'custom/about';
@import 'custom/property-details';
@import 'custom/resident-services';
@import 'custom/posts';
