// Resident Services Styles

#resident-services-content {
  margin-top: 50px;
  button {
    background-color: #0D6D3B !important;
  }
}

.maint-feeback-form {
  input[type="submit"] {
    background-color: #0D6D3B;
  }
}

.residents-message {
  font-size: 1.15rem;
  margin-top: 15px;
}
