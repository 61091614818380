// Property Details Page Styles
/* Property Details Page Styles */
#featured-image-banner {
  margin-bottom: 25px;
  img {
    width: 100%;
  }
}

#property-images {
  display: block;
  position: relative;
  top: -125px;
  // margin-bottom: -100px;
  width: 80%;
  margin: 0 auto -100px auto;
  ul#property-images-gallery {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    list-style-type: none;
    li {
      display: inline-block;
      margin: 0 auto 0 17.5px;
      // flex-grow: 1;
    }
  }
  li.property-image {
    img {
      width: 200px;
      height: 200px;
      border: 1px solid $black;
    }
  }
}

h1.property-address {
  font-weight: bold;
  font-size: 2rem;
}

ul#property-actions {
  li.residentServicesButton {
    list-style-type: none;
    background-color: $shamcoGreen;
    margin-top: 5px;
    a.residentServices {
      padding-bottom: 15px !important;
      vertical-align: middle;
      text-align: center;
      display: block;
      font-size: 1.5rem;
      line-height: 1rem;
      color: $white !important;
    }
  }
}

h4.property-desc-subtitle {
  margin-bottom: 0 !important;
  font-weight: bold;
}

h4.property-subtitle {
  font-weight: bold;
}

.property-details-content {
  p {
    font-size: 1rem;
    line-height: 1.5rem;
    width: 80%;
    margin: 15px auto 0;
  }
}

.property-details-additional-info {
  font-size: 1rem;
}

.property-contact-info {
  .property-contact {
    margin-top: -10px;
    margin-bottom: 10px;
    font-size: 1rem;
    margin-left: 15px;
  }
}

img.floorplan-image {
  width: 300px;
  height: auto;
  display: block;
  margin: 0 auto;
}

h6.section-title {
  font-weight: bold;
  font-size: 1.25rem;
  text-align: center;
}

.property-office-hours {
  margin-top: 15px;
  background-color: #ccc;
  padding: 5px;
  width: 275px;
  text-align: center;
  display: block;
  margin: 0 auto;
}

.property-caption {
  text-align: center;
  font-size: 0.975em;
  color: rgba(0,0,0,0.5);
}

figure:hover {
  .property-caption {
    color: rgba(0,0,0,1.0);
  }
}
