// Header Sass Partial
/* Header Styles */
body:not(.template-slider) #Header {
  min-height:0;
}

.homeSliderText {
  // line-height: 3.5rem;
  h2.welcomeTo {
    color: $shamcoGreen;
    font-size: 4rem;
    font-weight: bold;
    line-height: 4.5rem;
  }
  h1.shamco {
    color: $darkNavy;
    font-weight: bold;
    font-size: 7.25rem;
    line-height: 5.5rem;
  }
  h2.subShamco {
    color: $darkNavy;
    font-weight: bold;
    font-size: 3rem;
  }
}
