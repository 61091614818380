// About Page Styles

.aboutShamco {
  font-size: 1rem;
  margin-top: 15px;
  width: 75%;
  display: block;
  margin-left: 12.5%;
  text-align: center;
}

h2.propertiesTitle {
text-align: center;
font-size: 3.5rem;
font-weight: bold;
text-transform: uppercase;
font-family: 'Lato';
margin-bottom:  50px;
margin-top: 15px;
}

h3.management {
  font-weight: bold;
  font-family: 'Lato';
}

ul.management-services {
  text-align: left;
  &:nth-of-type(1) {
    margin-bottom: 50px;
  }
}

ul.management-services:nth-of-type(2) {
  margin-top: 25px;
}

div.management-first {
  margin-bottom: 75px;
}

.wpcf7-submit {
  background-color: $shamcoGreen !important;
  font-size: 1rem;
}
