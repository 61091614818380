// Homepage Partial Stylesheet
/* Homepage Styles */

#home-second-section {
  min-height: 600px;
}

.weAreHome {
  .wpb_wrapper{
    width: 100%;
  }
}

.homeLeftColImg {
  height: 550px;
  width: auto;
}

#home-buttons-section {
  width: 100%;
  position: relative;
  top: -225px;
}

@media only screen and (min-width: 801px) {
  .homeButtons {
    .col-span-third {
      width: 32%;
      display: inline-block;
      a {
        color: #FFF;
        text-align: center;
        padding-top: 30%;
        font-size: 1.5rem;
      }
      a:last-child {
        float: right;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .homeButtons {
    margin-top: 200px;
    .col-span-third {
      width: 100%;
      display: block;
      margin-top: 5px;
      margin-bottom: 5px;
      a {
        color: #FFF;
        text-align: center;
        font-size: 18px;
      }
    }
  }
}

.homeButton {
  width: 375px;
  height: 135px;
  display: inline-block;
}


#schedule-viewing {
  background-image: url('../../../uploads/2016/03/schedule-viewing-button.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

#appointment {
  background-image: url('../../../uploads/2016/03/application-button.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

#resident-services {
  background-image: url('../../../uploads/2016/03/residential-services-button.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.weAreHome {
  .weAreText {
    font-size: 1rem;
    line-height: 2rem;
  }
}

// Properties Section
/* Properties Section */

#properties-row {
  position: relative;
  top: -225px;
  background-color: $offWhite;
  padding-top: 25px;
}

.propertyDesc {
  text-align: center;
  font-family: 'Roboto';
  font-size: 1rem;
  margin-top: -25px;
}

a.propertiesLink {
  text-align: center;
  display: block;
  color: $darkNavy;
  font-size: 1rem;
  font-weight: bold;
}

div.greenHeader {
  background-color: $shamcoGreen;
  width: 1100%;
  display: block;
  margin-left:0;
  padding-top: 7.5px;
  padding-bottom: 5px;
  h4.fromBlog {
    text-align: center;
    color: $white;
  }
}
