// Global Sass Partial Stylesheet


/* Page Styles */

h4.hat-tip {
  font-weight: bold;
  a {
    text-decoration: underline;
    color: #2991d6;
  }
}


/* Footer Styles */

// Copyright
.copyright {
  color: $white;
}

.footer_copy {
  background-color: $shamcoGreen;
}

.logoKo {
  width: auto;
  height: 75px !important;
  margin-bottom: 15px;
}

.contactItem {
  a {
    color: #8A8C8E !important;
  }
  margin-bottom: 10px;
}

.contactSocial {
  list-style-type: none;
  .contactSocialItem {
    display: inline-block;
    margin: 0 auto;
    padding-left: 5px;
    padding-right: 5px;
  }
}

/* Resident Services Footer Buttons */

ul.servicesListFooter {
  padding-top: 15px;
  li.residentServicesButton {
    background-color: $offWhite;
    margin-top: 5px;
    margin-bottom: 20px;
    width: 70%;
    margin-left: 15%;
    a.residentServices {
      padding-top: 7.5px;
      padding-bottom: 7.5px;
      text-align: center;
      display: block;
      font-size: 1rem;
      color: $darkNavy !important;
    }
  }
  li.residentServicesButton:hover {
    background-color: $lightGray;
    a.residentServices {
      color: $white !important;
    }
  }
}


#gform_1, #gform_2, #gform_3 {
  input[type="submit"] {
    background-color: $shamcoGreen;
  }
}

.emodal-close {
  background-color: $shamcoGreen !important;
}

.emodal {
  border-color: $shamcoGreen !important;
}

.floorplan-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  width: 80%;
  margin: 25px auto 0 auto;
  flex-grow: 1;
  .floorplan-button {
    // flex-grow: 1;
  }
}
