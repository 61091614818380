// Properties Page & Single Properties Pages Styles

/* Properties Page Styles */

.propertiesPageContent {
  h2.propertiesTitle {
  text-align: center;
  font-size: 3.5rem;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Lato';
  }
  p.propertiesText {
    max-width: 960px;
    padding-top: 25px;
    margin: 0 auto;
    display: block;
    text-align: center;
    font-size: 1rem;
    line-height: 2.5rem;
  }
}

#njPropertyPage {
  .wpb_wrapper {
    margin-bottom: 0px !important;
  }
  #nj-property-header {
    text-align: center;
    background-image: url('../../../uploads/2016/03/properties-sub-header.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    // background-attachment: fixed;
    height: 275px;
    width: 100%;
    padding-top: 25px;
    h1 {
      font-size: 5rem;
      font-weight: bold;
      font-family: 'Lato';
      color: $shamcoGreen;
      margin-top: 100px;
    }
  }
}

#njPropertyBlock {
  margin-top: 0;
}

#nyPropertyPage {
  #ny-property-header {
    text-align: center;
    background-image: url('../../../uploads/2016/04/ny-properties-subheader.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    height: 275px;
    width: 100%;
    padding-top: 25px;
    margin-bottom: 0;
    h1 {
      font-size: 5rem;
      font-weight: bold;
      font-family: 'Lato';
      color: $shamcoGreen;
      margin-top: 100px;
    }
  }
}

.propertiesTextBlock {
  margin-top: 25px;
  max-width: 75%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.25rem;
  font-family: 'Roboto';
  line-height: 2rem;
}

div.propertyButtons {
  .propertyButton {
    display: block;
    background-color: $shamcoGreen;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 10px;
    width: 325px;
    font-size: 1.25rem;
    text-transform: uppercase;
    color: $white;
    text-align: center;
    margin-left: 12.5%;
  }
}

.propertiesPageImages {
  max-width: 960px;
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
}

// Properties Page Background Image
#propertiesPage {
  background-image: url('../../../uploads/2016/04/properties-page-bg.jpg');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-attachment: fixed;
}

.propertiesRow {
  padding-top: 30px;
}

#ny-properties-top-row {
  max-height: 425px;
}
