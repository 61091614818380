////
/// Variables Partial
/// @group Shamco
////

/// Color Variables
$black: #000;
$white: #FFF;
$shamcoGreen: #0D6D3B;
$darkNavy: #2C4559;
$lightGray: #8A8C8E;
$offWhite: #E4E4E6;
